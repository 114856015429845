<template>
  <div class="admin-list">
    <h4 class="list-title">{{ $lang.app.users }}</h4>
    <div class="filters">
      <Form @submit="submit">
        <div class="filters-content">
          <div class="filters-item filter-text">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.pin }}</label>
              <div class="reg-step-1-select-container">
                <Field name="pin" type="text"
                       v-maska="'##############'"
                       v-model="filterForm.pin"/>
                <div class="tooltip-target">
                  <i class="bi-question-circle"></i>
                  <div class="tooltip-content">
                    <p>{{ $lang.app.id_card_des }}</p>
                    <p>{{ $lang.app.id_card_example }}</p>
                    <div class="passport-images">
                      <div class="passport-image">
                        <img src="../../../assets/images/pin-2017.png" alt="">
                        <span>{{ $lang.app.id_card }}</span>
                      </div>
                      <div class="passport-image">
                        <img src="../../../assets/images/pin-2004.png" alt="">
                        <span>{{ $lang.app.passport }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="filters-item filter-text">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.surname }}</label>
              <div class="reg-step-1-select-container with-letter-buttons">
                <Field name="surname" type="text"
                       v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                       :title="$lang.app.only_cyrillic"
                       v-model="filterForm.surname"/>
                <ExtraKyrgyzLetters @change="filterForm.surname += $event"/>
              </div>
            </div>
          </div>
          <div class="filters-item filter-text">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.name }}</label>
              <div class="reg-step-1-select-container">
                <Field name="name" type="text"
                       v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                       :title="$lang.app.only_cyrillic"
                       v-model="filterForm.name"/>
                <ExtraKyrgyzLetters @change="filterForm.name += $event"/>
              </div>
            </div>
          </div>
          <div class="filters-item filter-text">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.middle_name }}</label>
              <div class="reg-step-1-select-container">
                <Field name="middleName" type="text"
                       v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                       :title="$lang.app.only_cyrillic"
                       v-model="filterForm.middleName"/>
                <ExtraKyrgyzLetters @change="filterForm.middleName += $event"/>
              </div>
            </div>
          </div>
          <div class="filters-item filter-text">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.phone_number }}</label>
              <div class="reg-step-1-select-container">
                <Field name="phone" type="text"
                       v-model="filterForm.phone"
                       autocomplete="off"/>
              </div>
            </div>
          </div>
          <div class="filters-item filter-text">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.email }}</label>
              <div class="reg-step-1-select-container">
                <Field name="email" type="email"
                       v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[^а-яА-Я]/ }}}"
                       v-model="filterForm.email"/>
              </div>
            </div>
          </div>
          <div class="filters-item filter-text">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.citizenship }}</label>
              <div class="reg-step-1-select-container">
                <i class="bi-chevron-down"></i>
                <Field as="select" name="citizenship"
                       v-model="filterForm.citizenship">
                  <option value="" disabled>{{ $lang.app.select_from }}</option>
                  <option v-for="citizenship in helper.citizenships" :key="citizenship.value"
                          :value="citizenship.value">{{ citizenship.label}}</option>
                </Field>
              </div>
            </div>
          </div>
          <div v-if="filterForm.citizenship === 'FOREIGN'" key="FOREIGN"
               class="filters-item filter-text double-input">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.country_code }}</label>
              <div class="reg-step-1-select-container select-control-wrapper">
                <i class="bi-chevron-down"></i>
                <Field name="foreignCode" v-slot="{ field }">
                  <Multiselect
                      v-bind="field"
                      v-model="filterForm.foreignCode"
                      :options="helper.countryCodes"
                      placeholder=""
                      valueProp="code"
                      label="title"
                      :searchable="true"
                      :noOptionsText="$lang.app.list_empty"
                      :noResultsText="$lang.app.not_found">
                  </Multiselect>
                </Field>
              </div>
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.document_number }}</label>
              <div class="reg-step-1-select-container">
                <Field name="foreignDoc" type="text"
                       v-model="filterForm.foreignDoc"/>
              </div>
            </div>
          </div>
          <div v-if="filterForm.citizenship === 'KG'" key="KG" class="filters-item filter-text double-input">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.passport_seria }}</label>
              <div class="reg-step-1-select-container">
                <i class="bi-chevron-down"></i>
                <Field as="select" name="passportRange"
                       v-model="filterForm.passportRange">
                  <option v-for="passportRange in helper.passportRanges" :key="passportRange"
                          :value="passportRange">{{ passportRange }}</option>
                </Field>
              </div>
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.passport_number }}</label>
              <div class="reg-step-1-select-container">
                <Field name="passportId" type="text"
                       v-maska="'#######'"
                       v-model="filterForm.passportId"/>
                <div class="tooltip-target">
                  <i class="bi-question-circle"></i>
                  <div class="tooltip-content tooltip-content-small">
                    <p>{{ $lang.app.passport_number_des }}</p>
                    <p>{{ $lang.app.passport_number_example }}</p>
                    <div class="passport-images">
                      <div class="passport-image">
                        <img src="../../../assets/images/id-2017.png" alt="">
                        <span>{{ $lang.app.id_card }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="reg-step-btn">
          <button type="button" class="button-transparent" @click.prevent="resetFilters">{{ $lang.app.reset }}</button>
          <button type="submit">{{ $lang.app.search }}</button>
        </div>
      </Form>
    </div>
    <div class="admin-table">
      <table>
        <thead>
        <tr>
          <th class="key">{{ $lang.app.pin }}</th>
          <th>{{ $lang.app.fullname }}</th>
          <th>{{ $lang.app.phone_number }}</th>
          <th>{{ $lang.app.email }}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="applicant in applicants" :key="applicant.id">
          <td class="key">{{ applicant.pin }}</td>
          <td>{{ applicant.fio }}</td>
          <td>{{ applicant.phone }}</td>
          <td>{{ applicant.email }}</td>
          <td>
            <div class="action-buttons">
              <router-link :to="`/admin/applicants/${applicant.id}`"
                           class="action-button" :title="$lang.app.view"><i class="bi-info-square"></i></router-link>
              <router-link :to="`/admin/applicants/${applicant.id}/edit`"
                           class="action-button" :title="$lang.app.edit"><i class="bi-pencil-square"></i></router-link>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { maska } from 'maska'
import { Field, Form } from 'vee-validate'
import ExtraKyrgyzLetters from '../../../components/ExtraKyrgyzLetters.vue'

export default {
  components: {
    Field,
    Form,
    ExtraKyrgyzLetters,
  },
  directives: { maska },
  data() {
    return {
      filterForm: {
        pin: '',
        surname: '',
        name: '',
        middleName: '',
        citizenship: null,
        passportRange: '',
        passportId: '',
        foreignCode: '',
        foreignDoc: '',
        phone: '',
        email: '',
      },
      helper: {
        citizenships: [
          {
            label: this.$lang.app.citizen_kg,
            value: 'KG'
          },
          {
            label: this.$lang.app.citizen_other,
            value: 'FOREIGN'
          }
        ],
        passportRanges: ['AN', 'ID'],
        countryCodes: [],
      },
      applicants: [],
    }
  },
  created() {
    this.setCountryCodes()
    this.initFilterForm()
    this.search()
  },
  watch: {
    '$route'(value, oldValue) {
      if(value.path === oldValue.path) {
        this.search()
      }
    }
  },
  methods: {
    setCountryCodes() {
      this.$axios.get(`/country`).then(({data}) => {
        this.helper.countryCodes = data
      })
    },
    initFilterForm() {
      this.filterForm = {
        pin: this.$route.query.pin || '',
        surname: this.$route.query.surname || '',
        name: this.$route.query.name || '',
        middleName: this.$route.query.middleName || '',
        citizenship: this.$route.query.citizenship || null,
        passportRange: this.$route.query.passportRange || '',
        passportId: this.$route.query.passportId || '',
        foreignCode: this.$route.query.foreignCode || '',
        foreignDoc: this.$route.query.foreignDoc || '',
        phone: this.$route.query.phone || '',
        email: this.$route.query.email || '',
      }
    },
    search() {
      this.applicants = []
      this.$store.commit('startLoading')
      this.$axios.post(`/applicant/search`, this.filterForm)
          .then(({data}) => {
            this.applicants = data
          }).finally(() => {
            this.$store.commit('stopLoading')
          })
    },
    resetFilters() {
      this.filterForm = {
        pin: '',
        surname: '',
        name: '',
        middleName: '',
        citizenship: null,
        passportRange: '',
        passportId: '',
        foreignCode: '',
        foreignDoc: '',
        phone: '',
        email: '',
      }
    },
    submit() {
      this.$router.push({path:'/admin/applicants', query: this.filterForm })
    }
  },
}
</script>
<style>
@import '../../../assets/styles/admin/list.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/table.css';
@import '../../../assets/styles/form.css';
@import '../../../assets/styles/multiselect.css';
@import '../../../assets/styles/admin/list-navbar.css';
.filters {
  margin-bottom: 30px;
}
.filters-content {
  flex-wrap: wrap;
}
.filters-item {
  width: 30%;
}
</style>