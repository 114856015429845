<template>
  <div class="letter-buttons">
    <button type="button" class="letter-button"
            @click.prevent="writeLetter($event,'Ң', 'ң')">ң</button>
    <button type="button" class="letter-button"
            @click.prevent="writeLetter($event,'Ө', 'ө')">ө</button>
    <button type="button" class="letter-button"
            @click.prevent="writeLetter($event,'Ү', 'ү')">ү</button>
  </div>
</template>
<script>
export default {
  methods: {
    writeLetter(event, capitalLetter, letter) {
      if (event.shiftKey) {
        this.$emit('change', capitalLetter)
      } else {
        this.$emit('change', letter)
      }
    },
  }
}
</script>
<style>
.letter-buttons {
  display: flex;
  flex-wrap: nowrap;
}
.letter-button {
  width: 24px;
  height: 30px;
  margin: 0 2px;
  background: none;
  border: 1px solid gray;
  line-height: 1;
  outline: none;
  box-shadow: none;
}
</style>